import React from 'react'
import windowsImg from '../images/downloadWindows.png'
import appleImg from '../images/downloadApple.png'
import androidImg from '../images/downloadAndroid.png'

const ProductSoftware = props => {
    const { software } = props
    return (
        <div>
            <h2 className="mt-8 pt-4 border-t border-secondary md:hidden  text-lg md:text-xl 2xl:text-2xl text-primary">Software</h2>
            <div className="grid grid-cols-2 pt-4">
                {software.filter(software => software.platforms == "Android" || software.platforms == "Apple").length > 0 &&

                    <div className="border-r border-secondary md:w-full 2xl:w-5/6">
                        <span className="md:text-xl font-bold">For Mobile</span>
                        <div className="flex flex-wrap gap-8 mt-2 ">
                            {software.filter(software => software.platforms == "Android" || software.platforms == "Apple").map(software => {
                                return (
                                    <a href={software.file.localFile.publicURL} download={software.fileName}>
                                        {software.platforms == "Android" ? <img src={androidImg} alt="Android Software" className="md:h-16 2xl:h-20" /> : <img src={appleImg} alt="Apple Software" className="md:h-16 2xl:h-20" />}
                                        <span>{software.title}</span>
                                        <br />
                                        <span>Ver: {software.version} | Release: {software.releaseDate}</span>
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                }
                {software.filter(software => software.platforms !== "Android" && software.platforms !== "Apple").length > 0 &&

                    <div className="md:pl-8 2xl:p-0">
                        <span className="md:text-xl font-bold">For Desktop</span>
                        <div className="mt-2 text-sm">
                            {software.filter(software => software.platforms !== "Android" && software.platforms !== "Apple").map(software => {
                                return (
                                    <a href={software.file.localFile.publicURL} download={software.fileName}>
                                        <img
                                            src={windowsImg}
                                            alt="Windows Software"
                                            className="pb-2 md:h-16 2xl:h-20"
                                        />
                                        <span>{software.title}</span>
                                        <br />
                                        <span>Ver: {software.version} | Release: {software.releaseDate}</span>
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ProductSoftware