import React, { useState, useEffect } from "react";
import { RiFileDownloadFill } from "@react-icons/all-files/ri/RiFileDownloadFill"
import ProductSoftware from "./productSoftware";
import { IoMdArrowDropdown } from "@react-icons/all-files/io/IoMdArrowDropdown"

const ProductTabs = props => {
    const { product } = props;
    // const specifications = product.specifications.strapi_json_value
    const tabs = [
        {
            id: 0,
            title: 'Product Overview',
            view: product.description ? true : false
        },
        {
            id: 1,
            title: 'Technical Specification',
            view: product.specifications?.strapi_json_value?.length > 0 ? true : false
        },
        {
            id: 2,
            title: "Items Included",
            view: product.childStrapiProductItemsincludedTextnode ? true : false
        },
        {
            id: 3,
            title: 'Downloads',
            view: product.doctypes.length > 0 ? true : false
        },
        {
            id: 4,
            title: 'Software',
            view: product.software.length > 0 ? true : false
        },
        {
            id: 5,
            title: 'Packaging Info',
            view: product.packageDimensions ? true : false
        }
    ];

    const [currentTab, setCurrentTab] = useState('1');

    const handleTabClick = (e) => {
        setCurrentTab(e.target.id);
    }

    const handleShow = id => {
        document.getElementById(id).classList.toggle("hidden")
    }

    useEffect(() => {
        if (!tabs[1].view) {
            let viewSet = false
            tabs.map((tab, i) => {
                if (tab.view && !viewSet) {
                    setCurrentTab(i.toString())
                    viewSet = true
                }
            })
        }
    }, [])

    return (
        <div>
            <div className="block md:hidden">
                {product.description &&
                    <div>
                        <button onClick={() => handleShow("desc")} className="w-full flex items-center justify-between py-4 border-t border-secondary md:hidden  ">
                            <h2 className="text-lg md:text-xl 2xl:text-2xl text-primary">Product Overview</h2>
                            <IoMdArrowDropdown className="text-xl" />
                        </button>
                        <p id="desc" className="hidden pb-4">{product.description}</p>
                    </div>
                }
                {product.specifications &&
                    <div>
                        <h2 className="py-4 border-t border-secondary md:hidden  text-lg md:text-xl 2xl:text-2xl text-primary">Technical Specifications</h2>
                        {product.specifications.strapi_json_value.map(spec => {
                            return (
                                <div className="grid grid-cols-2 gap-16 py-2">
                                    <span className="font-medium">{spec.attribute}</span>
                                    <span>{spec.value}</span>
                                </div>
                            )
                        })}
                    </div>
                }
                {product.childStrapiProductItemsincludedTextnode &&
                    <div>
                        <h2 className="mt-8 pt-4 border-t border-secondary md:hidden  text-lg md:text-xl 2xl:text-2xl text-primary">Items Included</h2>
                        <div className='pl-4 pt-4 features items-included-mobile' dangerouslySetInnerHTML={{ __html: product.childStrapiProductItemsincludedTextnode?.childMarkdownRemark.html }} />
                    </div>
                }
                {product.doctypes &&
                    <div>
                        <h2 className="mt-8 pt-4 border-t border-secondary md:hidden  text-lg md:text-xl 2xl:text-2xl text-primary">Downloads</h2>
                        <div className="grid grid-cols-2">
                            {product.doctypes.map(doc => {
                                return (
                                    <a
                                        href={doc?.upload?.localFile?.publicURL}
                                        download={doc?.upload?.localFile?.extension === "pdf" ? false : doc?.fileName}
                                        target="_blank"
                                        className="py-2 flex flex-col items-center"
                                    >
                                        <RiFileDownloadFill className="text-7xl text-primary" />
                                        {doc.fileType}
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                }
                {product.software.length > 0 &&
                    <ProductSoftware software={product.software} />
                }
                {product.packageDimensions ?
                    <div>
                        <h2 className="mt-8 pt-4 border-t border-secondary md:hidden  text-lg md:text-xl 2xl:text-2xl text-primary">Packaging Info</h2>
                        <ul>
                            <li className="grid grid-cols-2 gap-16 py-2">
                                <span>Width</span>
                                <span>{product.packageDimensions.width} cm</span>
                            </li>
                            <li className="grid grid-cols-2 gap-16 py-2">
                                <span>Length</span>
                                <span>{product.packageDimensions.length} cm</span>
                            </li>
                            <li className="grid grid-cols-2 gap-16 py-2">
                                <span>Height</span>
                                <span>{product.packageDimensions.height} cm</span>
                            </li>
                            <li className="grid grid-cols-2 gap-16 py-2">
                                <span>Weight</span>
                                <span>{product.packageDimensions.weight} kg</span>
                            </li>
                        </ul>

                    </div>
                    : null}
            </div>
            <div className="hidden md:block px-6">
                <div className='flex gap-12 text-lg lg:text-xl 2xl:text-2xl pb-4 2xl:pb-6 border-b border-secondary'>
                    {tabs.map((tab, i) => {

                        return (
                            tab.view === true ?
                                <button
                                    className={"pr-12 hover:underline hover:text-primary " + (currentTab === `${tab.id}` ? "text-primary underline" : "") + (i === 4 ? " " : "  border-r border-secondary")}
                                    key={i}
                                    id={tab.id}
                                    onClick={(handleTabClick)}
                                >
                                    {tab.title}
                                </button>
                                : null
                        )
                    }
                    )}
                </div>
                <div className=' bg-white'>
                    {currentTab === '0' &&
                        <p className="pt-6">{product.description}</p>
                    }
                    {currentTab === '1' &&
                        <ul className="text-lg">
                            {product.specifications.strapi_json_value.map(spec => {
                                return (
                                    <li className="flex py-1">
                                        <span className="w-64 font-bold">{spec.attribute}</span>
                                        <span>{spec.value}</span>
                                    </li>
                                )
                            })}
                        </ul>
                    }
                    {currentTab === '2' &&
                        <div className='px-4 features text-lg items-included' dangerouslySetInnerHTML={{ __html: product.childStrapiProductItemsincludedTextnode?.childMarkdownRemark.html }} />
                    }
                    {currentTab === '3' &&
                        <div>
                            <div className="grid grid-cols-4 2xl:grid-cols-5 gap-4 py-4">
                                {product.doctypes.map(doc => {
                                    return (
                                        <a
                                            href={doc?.upload?.localFile?.publicURL}
                                            target="_blank"
                                            download={doc?.upload?.localFile?.extension === "pdf" ? false : doc?.fileName}
                                            className="h-20 items-center flex flex-col"
                                        >
                                            <RiFileDownloadFill className="text-7xl text-primary" />
                                            {doc.fileType}
                                        </a>
                                    )
                                })}

                            </div>
                        </div>
                    }
                    {currentTab === '4' &&
                        <div className="py-4">
                            <ProductSoftware software={product.software} />
                        </div>
                    }
                    {currentTab === '5' &&
                        <ul className="">
                            <li className="flex py-1">
                                <span className="w-64 font-bold">Width</span>
                                <span>{product.packageDimensions.width} cm</span>
                            </li>
                            <li className="flex py-1">
                                <span className="w-64 font-bold">Length</span>
                                <span>{product.packageDimensions.length} cm</span>
                            </li>
                            <li className="flex py-1">
                                <span className="w-64 font-bold">Height</span>
                                <span>{product.packageDimensions.height} cm</span>
                            </li>
                            <li className="flex py-1">
                                <span className="w-64 font-bold">Weight</span>
                                <span>{product.packageDimensions.weight} Kg</span>
                            </li>
                        </ul>
                    }
                </div>
            </div>
        </div>
    );

}
export default ProductTabs
