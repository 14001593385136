import React from 'react'
import Layout from '../components/layout'
import { graphql, navigate } from 'gatsby'
import Carousel from '../components/carousel'
import Slider from '../components/slider'
import ProductTabs from '../components/productTabs'
import SliderTile from '../components/sliderTile'
import Breadcrumbs from '../components/breadcrumbs'
import Seo from '../components/seo'

export const query = graphql`
query($slug: String!, $subCategoryName: String!, $parentCategory: String! ){
    product: strapiProduct(slug: {eq: $slug}){
        model
        searchTitle
        slug
        url
        video
        description
        documents {
          localFile {
            publicURL
            name
          }
        }
        doctypes {
            fileName
            fileType
            upload {
                localFile {
                    publicURL
                    extension
                }
            }
        }
        software {
            platforms
            title
            version
            releaseDate
            fileName
            file {
                name
                ext
                localFile {
                    publicURL
                }
            }
        }
        category{
            name
            path
            level
        }
        childStrapiProductItemsincludedTextnode {
            childMarkdownRemark {
                html
            }
        }
        features: childStrapiProductFeaturesTextnode {
            childMarkdownRemark {
                html
            }
        }
        images {
            name
            localFile {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
        specifications {
            strapi_json_value {
                attribute
                value
            }
        }
        packageDimensions {
            height
            length
            weight
            width
        }
    }
    parent: strapiCategory(level: {eq: 2}, name: {eq: $parentCategory}){
        name
        path
        slug
        parentCategory
        level
    }
    simpleMainCategory: strapiCategory(level: {eq: 1}, name: {eq: $parentCategory}){
        name
        path
        slug
    }
    categories: allStrapiCategory(filter: {level: {eq: 1}}){
        nodes{
            name
            path
            slug
            level
        }
    }
    related: allStrapiProduct(filter: {
        category: {
            name: {eq: $subCategoryName}
        }
        slug: {
            ne: $slug
        }
        websiteActive:{
            eq:true
        }
    }) {
      nodes {
        id
        slug
        model
        searchTitle
        url
        images {
          name
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
}
`
export default function Product({ data }) {
    const product = data.product
    const relatedProducts = data.related.nodes
    const categories = data.categories.nodes
    const simpleMainCategory = data.simpleMainCategory
    var parent = data.parent || product.category
    var mainCategory = simpleMainCategory
    
    if (!simpleMainCategory){
        categories.map(category => {
            if (category.name === parent.parentCategory) {
                mainCategory = category
            }
        })
    }

    return (
        <Layout>
            <Seo title={product.model + " " + product.searchTitle}/>
            <div className="py-4 px-6">
                <Breadcrumbs
                    trail={
                        [
                            { title: mainCategory?.name, link: "/" + mainCategory?.path },
                            { title: parent.name, link: "/"+parent.path },
                            { title: product.model + " " + product.searchTitle, link: "/" + product.url},
                        ]
                    }
                /> 
                <div className="grid grid-cols-1 md:grid-cols-2 mt-4 min-h-[400px]">
                    <div className="md:px-8">
                        <h1 className='md:hidden text-xl md:text-2xl 2xl:text-3xl text-primary pb-2 '>{product.model + " " + product.searchTitle}</h1>
                        <Carousel images={product.images?.sort((a,b) => a.name > b.name ? 1:-1)} alt={product.searchTitle} video={product.video}/>
                    </div>
                    <div className='flex-col'>
                        <h1 className='hidden md:block  text-xl md:text-2xl 2xl:text-3xl text-primary pb-2 border-b border-[#D7D7D7]'>{product.model + " " + product.searchTitle}</h1>
                        <div className="grid grid-cols-1">
                            <div className="py-8 md:py-0 flex flex-wrap gap-4 md:gap-16">
                                <button
                                    type="submit"
                                    onClick={() => navigate("/distributors")}
                                    className='hover:text-primary hover:bg-transparent border hover:border-primary w-full md:w-60 text-lg  py-2 rounded-md bg-primary text-white transition-all duration-150'
                                >
                                    Find a Distributor
                                </button>
                                <button
                                    type="submit"
                                    onClick={() => navigate("/request", {
                                        state: { product },
                                    })}
                                    className='hover:text-primary hover:bg-transparent border hover:border-primary w-full md:w-60 text-lg  py-2 rounded-md bg-primary text-white transition-all duration-150'
                                >
                                    Request More Info
                                </button>
                            </div>
                            <h2 className="pt-4 border-t border-secondary md:hidden  text-lg md:text-xl 2xl:text-2xl text-primary">Features</h2>
                            <div className='features pt-4 md:pb-8 pl-4 order-last md:order-first' dangerouslySetInnerHTML={{ __html: product.features?.childMarkdownRemark.html }} />
                        </div>

                    </div>
                </div>
                <div className="mt-8 md:mt-16 2xl:mt-20 w-full">
                    <ProductTabs product={product} />
                </div>
            </div>
            {relatedProducts.length > 4 ?
                <Slider title={"explore more in " + product.category.name} products={relatedProducts} />
                :
                <div>
                    {relatedProducts.length > 0 &&
                        <div className=' py-4 px-8 md:py-8 md:px-12 '>
                            <h3 className="md:text-lg 2xl:text-xl text-primary mb-8">Explore more in {product.category.name}</h3>
                            <div className="grid grid-cols-2 lg:grid-cols-5 2xl:grid-cols-6">
                                {
                                    relatedProducts.map(product => <SliderTile product={product} />)
                                }
                            </div>
                        </div>
                    }
                </div>
            }
        </Layout>
    )
}

