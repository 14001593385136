import React, { useRef, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
// import required modules
import { FreeMode, Navigation, Thumbs, Controller } from "swiper";

// import leftIcon from '../images/leftIcon.png'
// import rightIcon from '../images/rightIcon.png'
import { BsChevronLeft } from "@react-icons/all-files/bs/BsChevronLeft";
import { BsChevronRight } from "@react-icons/all-files/bs/BsChevronRight";
import { ImPlay2 } from "@react-icons/all-files/im/ImPlay2";

const Carousel = props => {
    const { images, alt, video } = props;
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [swiper, setSwiper] = useState();

    React.useEffect(() => {
        if (swiper) {
            console.log("Swiper instance:", swiper);
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
        }
    }, [swiper]);

    const prevRef = useRef();
    const nextRef = useRef();

    return (
        <div>
            {/* main carousel */}
            <div className="flex items-center gap-2 justify-center mb-8 w-full">
                <div className="cursor-pointer text-xl" ref={prevRef}>
                    {/* <img src={leftIcon} className="w-full" alt="" /> */}
                    <BsChevronLeft />
                </div>
                <Swiper
                    navigation={{
                        prevEl: prevRef?.current,
                        nextEl: nextRef?.current
                    }}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[Navigation, Thumbs]}
                    onSwiper={setSwiper}
                    loop={true}
                    className="mySwiper2 external-buttons w-full md:w-4/5 md:h-[500px] border border-secondary"
                >
                    {images?.map(image => {
                        return (
                            <SwiperSlide className="">
                                <GatsbyImage
                                    image={getImage(image?.localFile)}
                                    class="h-full"
                                    objectFit="contain"
                                    alt={alt}
                                />
                            </SwiperSlide>
                        )
                    })}
                    {video &&
                        <SwiperSlide className="mx-auto">
                            <div className="hidden md:flex w-full h-full justify-center items-center">
                                <iframe width="500" height="500" src={video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                            </div>
                            <div className="flex md:hidden w-full h-full justify-center items-center">
                                <iframe width="200" height="300" src={video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                            </div>
                        </SwiperSlide>
                    }
                </Swiper>
                <div className="cursor-pointer text-xl" ref={nextRef}>
                    {/* <img src={rightIcon} className="w-" alt="" /> */}
                    <BsChevronRight />
                </div>
            </div>

            {/* thumbnail slider */}
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={6.5}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper w-full md:w-4/5"
            >
                {images?.map(image => {
                    return (
                        <SwiperSlide>
                            <div className="cursor-pointer hover:border-black border border-secondary  md:h-16">
                                <GatsbyImage
                                    image={getImage(image?.localFile)}
                                    class="md:w-full md:h-full"
                                    objectFit="contain"
                                    alt={alt}
                                />
                            </div>
                        </SwiperSlide>
                    )
                })}
                {video &&
                    <SwiperSlide>
                        <div className="group cursor-pointer hover:border-black w-full border flex h-11 md:h-16 items-center justify-center text-xl">
                            <ImPlay2 className="group-hover:text-primary" />
                        </div>
                    </SwiperSlide>
                }
            </Swiper>
        </div>
    );
}

export default Carousel